import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import {AnimatePresence} from 'framer-motion';
import Fonts from './src/fonts'
import theme from './src/theme';
export const wrapPageElement = ({element}) => (
    <ChakraProvider theme={theme}>
      <Fonts />
      <AnimatePresence/>
          {element}
      <AnimatePresence/>
    </ChakraProvider>
);
