import React from "react";
import { Global } from "@emotion/react";

const Fonts = () => (
	<Global
		styles={`
      @font-face {
        font-family: 'definition';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/static/fonts/font.woff2') format('woff2'), url('/static/fonts/font.woff') format('woff');
      }
      `}
	/>
);

export default Fonts;
