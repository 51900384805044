import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
	initialColorMode: "light",
	useSystemColorMode: false,

	fonts: {
		heading: "definition,serif, sans-serif",
		body: "definition,serif, sans-serif",
	},
	sizes: {
		container: {
			xxl: "1600px",
			xl: "1440px",
		},
	},
	breakpoints: {
		xxl: "1600px",
		xl: "1440px",
	},
	fontSizes: {
		xxs: "0.5rem",
		xs: "0.75rem",
		sm: "0.875rem",
		md: "1.0625rem",
		lg: "1.3125rem",
		xl: "1.5rem",
		"2xl": "1.75rem",
		"3xl": "2.25rem",
		"4xl": "2.625rem",
		"5xl": "3.5rem",
		"6xl": "4rem",
		"7xl": "4.5rem",
		"8xl": "6rem",
		"9xl": "8rem",
		"10xl": "10rem",
		"11xl": "16rem",
	},
	lineHeights: {
		undercut: "0.8",
		normal: "normal",
		none: 1,
		shorter: 1.25,
		short: 1.375,
		base: 1.5,
		tall: 1.625,
		taller: "2",
		3: ".75rem",
		4: "1rem",
		5: "1.25rem",
		6: "1.5rem",
		7: "1.75rem",
		8: "2rem",
		9: "2.25rem",
		10: "2.5rem",
	},
	letterSpacings: {
		tighter: "-0.05em",
		tight: "-0.025em",
		normal: "0",
		wide: "0.025em",
		wider: "0.05em",
		widest: "0.1em",
	},
	components: {
		Heading: {
			baseStyle: {
				fontWeight: "normal",
				lineHeight: "undercut",
				letterSpacing: "normal",
			},
		},
		Text: {
			baseStyle: {
				fontWeight: "normal",
				lineHeight: "shorter",
				letterSpacing: "tight",
			},
		},

		Button: {
			padding: 0,
			baseStyle: {
				borderRadius: "0",
				paddingLeft: "2rem",
			},
			variants: {
				solid: {
					padding: "1.7rem 5rem 1.7rem 1rem",
					display: "block",
					fontSize: "xs",
					letterSpacing: "wide",
					border: "1px solid ",
					borderColor: "brand.gray.1000",
					transition: "all .25s ease-in-out",
					_hover: {
						background: "brand.gray.1000",
						color: "brand.violet.primary",
						transition: "all .125s ease-in-out",
						border: "1px solid ",
						padding: "1.7rem 4rem 1.7rem 2rem",
					},
				},
				ghost: {
					padding: "1.7rem 5rem 1.7rem 1rem",
					display: "block",
					background: "transparent",
					color: "brand.violet.primary",
					transition: "all .25s ease-in-out",
					border: "1px solid",

					letterSpacing: "wide",
					_hover: {
						padding: "1.7rem 4rem 1.7rem 2rem",
						background: "brand.violet.primary",
						transition: "all .125s ease-in-out",
						color: "brand.gray.1000",
					},
				},
				ghostwhite: {
					padding: "1.7rem 5rem 1.7rem 1rem",
					display: "block",
					background: "transparent",
					color: "white",
					transition: "all .25s ease-in-out",
					border: "1px solid",
					letterSpacing: "wide",
					_hover: {
						padding: "1.7rem 4rem 1.7rem 2rem",
						background: "transparent",
						color: "brand.violet.primary",
						transition: "all .125s ease-in-out",
					},
				},
			},
		},
		Container: {
			variants: {
				layoutContainer: {
					maxW: {
						base: "calc(100% -1rem)",
						xl: "min(1680px,calc(100% - 1rem))",
					},
					boxSizing: "border-box",
					py: 0,
					px: { base: "0", xl: "0", xxl: "0" },
				},
			},
		},
		Link: {
			baseStyle: {
				_hover: {
					textDecoration: "none",
				},
			},
			variants: {
				navlink: {
					textDecoration: "none",
					_hover: { textDecoration: "underline" },
				},
			},
		},
	},
	colors: {
		brand: {
			violet: {
				50: "#06000F",
				100: "#150132",
				200: "#1D0147",
				300: "#39018D",
				400: "##4802B1",
				secondary: "#4802B1",
				500: "#6814E6",
				600: "#761DFC",
				primary: "#761DFC",
				700: "#9747FF",
				800: "#D8C5F7",
				900: "#E9DBFF",
			},
			green: {
				400: "#4BFF1E",
				tertiary: "#4BFF1E",
				500: "#64FF3D",
				primary: "#64FF3D",
				600: "#86FF68",
				secondary: "#86FF68",
			},
			gray: {
				100: "#1E1F24",
				200: "#27292F",
				300: "#31333A",
				400: "#444750",
				500: "#676C79",
				600: "#9095A2",
				700: "#A6AAB5",
				800: "#BABDC5",
				900: "#DBDDE1",
				950: "#E6E8EA",
				1000: "#F8F8FA",
				almostwhite: "#F8F8FA",
			},
		},
	},
});

export default theme;
